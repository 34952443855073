import React, { useState } from 'react';
import './App.css';
import MemoryColorsGame from './views/MemoryColorsGame';
import MatchingPairsGame from './views/MatchingPairsGame';

function App() {
  const [selectedGame, setSelectedGame] = useState(null);

  const handleGameSelection = (game) => {
    setSelectedGame(game);
  };

  const resetGameSelection = () => {
    setSelectedGame(null);
  };

  return (
    <div className="App">
      <header className="App-header">
        Juegos creados por: <b>Isabella Coronado Palacios</b>.
      </header>
      {!selectedGame ? (
        <div className="game-selection">
          <h1>Seleccione un Juego</h1>
          <div className="card-container">
            <div className="game-card" onClick={() => handleGameSelection('colors')}>
              <img src="/images/memoria_colores.png" alt="Memoria de Colores" />
              <h2>Juego de Memoria de Colores</h2>
            </div>
            <div className="game-card" onClick={() => handleGameSelection('pairs')}>
              <img src="/images/memoria_parejas.png" alt="Memoria de Parejas" />
              <h2>Juego de Parejas</h2>
            </div>
          </div>
        </div>
      ) : selectedGame === 'colors' ? (
        <MemoryColorsGame resetGameSelection={resetGameSelection} />
      ) : (
        <MatchingPairsGame resetGameSelection={resetGameSelection} />
      )}
    </div>
  );
}

export default App;
