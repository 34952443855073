import React, { useState, useEffect } from 'react';
import '../styles/MemoryColorsGame.css';

// Definición de colores con nombres en español
const COLORS = [
  { color: 'red', name: 'rojo' },
  { color: 'blue', name: 'azul' },
  { color: 'green', name: 'verde' },
  { color: 'yellow', name: 'amarillo' }
];

function MemoryColorsGame({ resetGameSelection }) {
  const [player1, setPlayer1] = useState('');
  const [player2, setPlayer2] = useState('');
  const [turn, setTurn] = useState(1);
  const [sequence, setSequence] = useState([]);
  const [currentInput, setCurrentInput] = useState([]);
  const [isPlaying, setIsPlaying] = useState(false);
  const [winner, setWinner] = useState(null);
  const [turnsPlayed, setTurnsPlayed] = useState(0);

  const startGame = () => {
    setIsPlaying(true);
    setSequence([]);
    setCurrentInput([]);
    setTurn(1);
    setWinner(null);
    setTurnsPlayed(0);
  };

  const handleColorClick = (color) => {
    if (!isPlaying || winner) return;

    const newInput = [...currentInput, color];
    setCurrentInput(newInput);

    if (newInput.length <= sequence.length) {
      if (sequence[newInput.length - 1] !== color) {
        setWinner(turn === 1 ? player2 : player1);
        setIsPlaying(false);
      } else if (newInput.length === sequence.length) {
        // Esperar a que el jugador siguiente seleccione su secuencia
      }
    } else {
      const updatedSequence = [...sequence, color];
      setSequence(updatedSequence);
      setCurrentInput([]);
      setTurnsPlayed((prevTurns) => prevTurns + 1);
      setTurn(turn === 1 ? 2 : 1);
    }
  };

  useEffect(() => {
    if (isPlaying && sequence.length === 0) {
      setCurrentInput([]);
    }
  }, [isPlaying]);

  return (
    <div>
      {!isPlaying && !winner ? (
        <div className="start-screen">
          <h2>Juego de Memoria: Colores</h2>
          <input
            type="text"
            placeholder="Nombre del Jugador 1"
            value={player1}
            onChange={(e) => setPlayer1(e.target.value)}
          />
          <input
            type="text"
            placeholder="Nombre del Jugador 2"
            value={player2}
            onChange={(e) => setPlayer2(e.target.value)}
          />
          <button onClick={startGame} disabled={!player1 || !player2}>
            Iniciar Juego
          </button>
        </div>
      ) : isPlaying ? (
        <div className="game-screen">
          <h2>Turno de: {turn === 1 ? player1 : player2}</h2>
          <div className="color-buttons">
            {COLORS.map(({ color, name }) => (
              <button
                key={color}
                style={{ backgroundColor: color }}
                onClick={() => handleColorClick(name)}
                className="color-button"
              />
            ))}
          </div>
          <p className="current-sequence">
            Secuencia Seleccionada: {currentInput.map((color) => COLORS.find(c => c.name === color)?.name).join(' - ')}
          </p>
          {/*<p>Secuencia Actual Correcta: {sequence.map((color) => COLORS.find(c => c.name === color)?.name).join(' - ')}</p>*/}
        </div>
      ) : (
        <div className="game-over">
          <h2>¡Fin del Juego!</h2>
          <h3>{winner} ha ganado!</h3>
          <p>Secuencia Final Correcta: {sequence.map((color) => COLORS.find(c => c.name === color)?.name).join(' - ')}</p>
          <p>Turnos Jugados: {turnsPlayed}</p>
          <button onClick={startGame}>Jugar de Nuevo</button>
          <button onClick={resetGameSelection}>Seleccionar nuevo juego</button>
        </div>
      )}
    </div>
  );
}

export default MemoryColorsGame;
