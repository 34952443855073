import React, { useState, useEffect } from 'react';
import '../styles/MatchingPairsGame.css';

const CARD_IMAGES = [
  { src: '/images/ballena.png', matched: false },
  { src: '/images/gato.png', matched: false },
  { src: '/images/jirafa.png', matched: false },
  { src: '/images/leon.png', matched: false },
  { src: '/images/mariposa.png', matched: false },
  { src: '/images/mono.png', matched: false },
  { src: '/images/perro.png', matched: false },
  { src: '/images/pinguino.png', matched: false },
  { src: '/images/pony.png', matched: false },
  { src: '/images/tigre.png', matched: false },
];

function MatchingPairsGame({ resetGameSelection }) {
  const [player1, setPlayer1] = useState('');
  const [player2, setPlayer2] = useState('');
  const [turn, setTurn] = useState(1);
  const [cards, setCards] = useState([]);
  const [choiceOne, setChoiceOne] = useState(null);
  const [choiceTwo, setChoiceTwo] = useState(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const [winner, setWinner] = useState(null);
  const [player1Score, setPlayer1Score] = useState(0);
  const [player2Score, setPlayer2Score] = useState(0);
  const [disabled, setDisabled] = useState(false);

  const shuffleCards = () => {
    const shuffledCards = [...CARD_IMAGES, ...CARD_IMAGES]
      .sort(() => Math.random() - 0.5)
      .map((card) => ({ ...card, id: Math.random(), matched: false }));
    setCards(shuffledCards);
  };

  const startGame = () => {
    setIsPlaying(true);
    shuffleCards();
    setChoiceOne(null);
    setChoiceTwo(null);
    setTurn(1);
    setWinner(null);
    setPlayer1Score(0);
    setPlayer2Score(0);
  };

  const handleChoice = (card) => {
    if (!disabled && card !== choiceOne) {
      choiceOne ? setChoiceTwo(card) : setChoiceOne(card);
    }
  };

  useEffect(() => {
    if (choiceOne && choiceTwo) {
      setDisabled(true);
      if (choiceOne.src === choiceTwo.src) {
        setTimeout(() => {
          setCards((prevCards) =>
            prevCards.map((card) =>
              card.src === choiceOne.src ? { ...card, matched: true } : card
            )
          );
          turn === 1
            ? setPlayer1Score((prevScore) => prevScore + 1)
            : setPlayer2Score((prevScore) => prevScore + 1);
          resetTurn();
        }, 500);
      } else {
        setTimeout(() => resetTurn(), 1000);
      }
    }
  }, [choiceOne, choiceTwo]);

  const resetTurn = () => {
    setChoiceOne(null);
    setChoiceTwo(null);
    setTurn(turn === 1 ? 2 : 1);
    setDisabled(false);
  };

  useEffect(() => {
    if (isPlaying && cards.every((card) => card.matched === true)) {
      // Solo verificar el ganador si el juego ha comenzado
      setIsPlaying(false);
      if (player1Score > player2Score) {
        setWinner(player1);
      } else if (player2Score > player1Score) {
        setWinner(player2);
      } else {
        setWinner('Empate');
      }
    }
  }, [cards, player1Score, player2Score, player1, player2, isPlaying]); // Añadido isPlaying a las dependencias

  return (
    <div>
      {!isPlaying && !winner ? ( 
        <div className="start-screen">
          <h2>Juego de Parejas</h2>
          <input
            type="text"
            placeholder="Nombre del Jugador 1"
            value={player1}
            onChange={(e) => setPlayer1(e.target.value)}
          />
          <input
            type="text"
            placeholder="Nombre del Jugador 2"
            value={player2}
            onChange={(e) => setPlayer2(e.target.value)}
          />
          <button onClick={startGame} disabled={!player1 || !player2}>
            Iniciar Juego
          </button>
          <button onClick={resetGameSelection} style={{ marginTop: '10px' }}>
            Seleccionar nuevo juego
          </button>
        </div>
      ) : isPlaying ? (
        <div className="game-screen">
          <h2>Turno de: {turn === 1 ? player1 : player2}</h2>
          <div className="card-grid">
            {cards.map((card) => (
              <div
                key={card.id}
                className={`card ${card.matched ? 'fade-out' : ''} ${card.matched ? 'invisible' : ''}`}
              >
                <div
                  className={`card-inner ${card === choiceOne || card === choiceTwo || card.matched ? 'flipped' : ''}`}
                  onClick={() => handleChoice(card)} 
                >
                  <div className="card-front">
                    <img src="/images/card-front.png" alt="card back" />
                  </div>
                  <div className="card-back">
                    <img src={card.src} alt="card front" />
                  </div>
                </div>
              </div>
            ))}
          </div>
          <p className="score-text">Puntuación {player1}: {player1Score}</p>
          <p className="score-text">Puntuación {player2}: {player2Score}</p>
        </div>
      ) : (
        winner && (
        <div className="game-over">
          <h2>¡Fin del Juego!</h2>
          <h3>{winner === 'Empate' ? 'Es un empate!' : `${winner} ha ganado!`}</h3>
          <button onClick={startGame}>Jugar de Nuevo</button>
          <button onClick={resetGameSelection}>Seleccionar nuevo juego</button>
        </div>
      ))}
    </div>
  );
}

export default MatchingPairsGame;
